import moment from 'moment';
import db from 'db';
import TextHelpers from 'helpers/TextHelpers';
import App from 'App';

window.moment = moment;

export default class API {

    static accountsByID;
    static serviceTypesByID;
    static serviceTypesByCode;
    static chimneyTypesByID;
    static productsByID;
    static paymentMethods;
    static isProcessingQueue;

    static async log(msg) {
        console.log('API: ' + msg);
    }

    static async init() {
        const accounts = await db.accounts.toArray();
        API.accountsByID = {};
        accounts.forEach(a => {
            API.accountsByID[a.id] = a;
        });

        const serviceTypes = await db.serviceTypes.toArray();
        API.serviceTypesByID = {};
        API.serviceTypesByCode = {};
        serviceTypes.forEach(st => {
            API.serviceTypesByID[st.id] = st;
            API.serviceTypesByCode[st.code] = st;
        });

        const chimneyTypes = await db.chimneyTypes.toArray();
        API.chimneyTypesByID = {};
        chimneyTypes.forEach(ct => {
            API.chimneyTypesByID[ct.id] = ct;
        });

        const products = await db.products.toArray();
        API.productsByID = {};
        products.forEach(p => {
            API.productsByID[p.id] = p;
        });

        API.paymentMethods = await db.paymentMethods.toArray();

        API.scheduleProcessQueue(0);
    }

    static async downloadLatestData() {
        API.log('Loading new data from API');
        const data = await API.call('get-all-data');
        const tables = [
            db.accounts, db.appts, db.daySheetNotes, db.customers, db.properties, db.chimneyTypes,
            db.products, db.serviceTypes, db.paymentMethods, db.priceSchemes, db.customerSources
        ];

        API.log('Starting transaction');
        await db.transaction('rw', tables, async () => {
            API.log('Clearing existing data');

            // Clear all existing data
            await Promise.all(
                db.accounts.clear(),
                db.appts.clear(),
                db.daySheetNotes.clear(),
                db.customers.clear(),
                db.properties.clear(),
                db.chimneyTypes.clear(),
                db.products.clear(),
                db.serviceTypes.clear(),
                db.paymentMethods.clear(),
                db.priceSchemes.clear(),
                db.customerSources.clear()
            );

            API.log('Loading new data');

            API.log('Loading new data - accounts');
            await db.accounts.bulkAdd(data.accounts);
            API.log('Loading new data - appts');
            await db.appts.bulkAdd(data.appts);
            API.log('Loading new data - day sheet notes');
            await db.daySheetNotes.bulkAdd(data.daySheetNotes);
            API.log('Loading new data - customers');
            await db.customers.bulkAdd(data.customers);
            API.log('Loading new data - properties');
            await db.properties.bulkAdd(data.properties);
            API.log('Loading new data - chimney types');
            await db.chimneyTypes.bulkAdd(data.chimneyTypes);
            API.log('Loading new data - products');
            await db.products.bulkAdd(data.products);
            API.log('Loading new data - service types');
            await db.serviceTypes.bulkAdd(data.serviceTypes);
            API.log('Loading new data - payment methods');
            await db.paymentMethods.bulkAdd(data.paymentMethods);
            API.log('Loading new data - price schemes');
            await db.priceSchemes.bulkAdd(data.priceSchemes);
            API.log('Loading new data - customer sources');
            await db.customerSources.bulkAdd(data.customerSources)

            await API.init();
        });
    }

    static async enqueueCall(url, data) {
        const apiCall = {
            id: TextHelpers.getRandomGUID(),
            sequence: parseInt(moment().format('x')),
            url,
            data
        };
        await db.apiCalls.put(apiCall);
        API.scheduleProcessQueue(0);
    }

    static scheduleProcessQueue(delaySeconds) {
        if (!API.processQueueTimeout) {
            API.processQueueTimeout = setTimeout(async () => {
                const nextDelay = await API.processQueue();
                API.processQueueTimeout = null;
                if (nextDelay == -1) {
                    window.updateSyncStatus(null);
                } else {
                    API.scheduleProcessQueue(nextDelay);
                }
            }, delaySeconds * 1000);
        }
    }

    static async isQueueProcessed() {
        if (API.isProcessingQueue) return false;
        const apiCalls = await db.apiCalls.toArray();
        if (apiCalls.length > 0) return false;
        return true;
    }

    static async processQueue() {
        if (API.isProcessingQueue) {
            return false;
        }
        let apiCalls = await db.apiCalls.orderBy('sequence').toArray();
        if (apiCalls.length == 0) {
            window.updateSyncStatus(null);
        } else if (navigator.onLine) {
            API.isProcessingQueue = true;
            const apiCall = apiCalls[0];
            try {
                if (window.syncStatus != 'error') {
                    window.updateSyncStatus('busy');
                }
                await API.call(apiCall.url, apiCall.data);
                await db.apiCalls.delete(apiCall.id);
            } catch (ex) {
                window.updateSyncStatus('error', ex);
                API.isProcessingQueue = false;
                return 10;
            }
        } else {
            window.updateSyncStatus('error', 'You are not currently connected to the internet');
            API.isProcessingQueue = false;
            return 0.5;
        }
        API.isProcessingQueue = false;

        // Determine if we have any more API calls to process
        apiCalls = await db.apiCalls.orderBy('sequence').toArray();
        if (apiCalls.length > 0) {
            return 0;
        }

        return -1;
    }

    static async call(url, data) {
        try {
            data = { ...data, version: App.VERSION };
            const opt = {
                method: (data.method || 'POST'),
                headers: {
                    'Content-Type': 'application/json',
                    ...API.extraHeaders,
                    'x-version': App.VERSION
                }
            };
            if (data.method) {
                delete data.method;
            }
            if ((opt.method || '').toLowerCase() != 'get') {
                opt.body = JSON.stringify(data);
            }
            const response = await fetch('/api/' + url, opt);
            const text = await response.text();
            let responseObject = null;
            if (text.length > 0) {
                responseObject = JSON.parse(text);
            }

            switch (response.status) {
                case 200:
                    if (responseObject) {
                        if (responseObject.success) {
                            return responseObject.content;
                        } else {
                            throw responseObject.content; // Error message
                        }
                    } else {
                        return null;
                    }
                case 400:
                case 401:
                case 403:
                case 500:
                    if (responseObject) {
                        throw responseObject.content;
                    }
                    break;
            }

        } catch (error) {
            throw error;
        }
    }

    //static async postFile(url, file, data) {
    //    try {

    //        // Create form data object
    //        var formData = new FormData();
    //        formData.append('file', file);
    //        if (data) {
    //            for (var key in data) {
    //                formData.append(key, data[key])
    //            }
    //        }

    //        // Do the upload
    //        const response = await fetch('/api/' + url, {
    //            method: 'POST',
    //            body: formData
    //        });
    //        const text = await response.text();
    //        if (text.length > 0) {
    //            const responseObject = JSON.parse(text);
    //            return responseObject;
    //        } else {
    //            // TODO handle differently?
    //            return null;
    //        }
    //    } catch (error) {
    //        console.log('API error: ', error);
    //        return {
    //            success: false,
    //            message: error
    //        };
    //    }

    //}

    static serialiseValue(value) {
        if (Array.isArray(value)) {
            return value.join(',');
        }
        return value;
    }

    //------------------------------------------------------------------------------------------------------------------
    // Day sheet notes
    //------------------------------------------------------------------------------------------------------------------

    static async listDaySheetNotes(date) {
        date = moment(date).format('YYYY-MM-DD');
        const daySheetNotes = await db.daySheetNotes.where({ date }).toArray();
        for (let i = 0; i < daySheetNotes.length; i++) {
            const daySheetNote = daySheetNotes[i];
            daySheetNote.account = API.accountsByID[daySheetNote.accountID];
        }
        return daySheetNotes;
    }

    //------------------------------------------------------------------------------------------------------------------
    // Appointment
    //------------------------------------------------------------------------------------------------------------------

    static async listAppts(status) {
        const appts = await db.appts.where({
            status
        }).toArray();
        appts.sort((a, b) => a.sortOrder - b.sortOrder);
        for (let i = 0; i < appts.length; i++) {
            const appt = appts[i];
            await API._loadRelatedApptInfo(appt);
        }
        return appts;
    }

    static async getAppt(id) {
        const appt = await db.appts.get(id);
        if (appt) {
            await API._loadRelatedApptInfo(appt);
        }
        return appt;
    }

    static async _loadRelatedApptInfo(appt) {
        appt.accountName = (API.accountsByID[appt.accountID] ? API.accountsByID[appt.accountID].name : null);
        if (appt.propertyID) {
            appt.property = await db.properties.get(appt.propertyID);

            // Load service / chimney type info for displaying appt summary
            for (let i = 0; i < appt.services.length; i++) {
                const ast = appt.services[i];
                if (ast.chimneyID) {
                    ast.chimney = appt.property.chimneys.find(c => c.id == ast.chimneyID);
                    if (ast.chimney) {
                        ast.chimneyType = API.chimneyTypesByID[ast.chimney.chimneyTypeID]
                    }
                }
                ast.serviceType = API.serviceTypesByID[ast.serviceTypeID];
                if (ast.cowlProductID) {
                    ast.cowlProduct = await db.products.get(ast.cowlProductID);
                }
            }

            // Load product information
            for (let i = 0; i < appt.products.length; i++) {
                const ap = appt.products[i];
                if (ap.isDeleted) {
                    appt.products.splice(i, 1);
                    i--;
                    continue;
                }
                if (ap.productID) {
                    ap.product = await db.products.get(ap.productID);
                }
            }

            // Remove deleted payments
            for (let i = 0; i < appt.payments.length; i++) {
                const ap = appt.payments[i];
                if (ap.isDeleted) {
                    appt.payments.splice(i, 1);
                    i--;
                    continue;
                }
            }
        }
        if (appt.customerID) {
            appt.customer = await db.customers.get(appt.customerID);
        }
    }

    static async saveApptService(apptID, chimneyID, serviceTypeID, fields) {
        let isChanged = false;
        await db.appts.where({ id: apptID }).modify(async appt => {
            let service = appt.services.find(ast => ast.chimneyID == chimneyID && ast.serviceTypeID == serviceTypeID);
            if (!service) {
                const serviceType = API.serviceTypesByID[serviceTypeID];
                service = {
                    apptID,
                    chimneyID,
                    serviceTypeID,
                    serviceType
                };
                appt.services.push(service);
            }
            for (let fieldName in fields) {
                const value = fields[fieldName];
                if (service[fieldName] != value) {
                    service[fieldName] = value;
                    isChanged = true;
                }
            }
        });
        if (isChanged) {
            API.enqueueCall('appt/update-service', { apptID, chimneyID, serviceTypeID, fields });
        }
    }

    static async saveAppt(id, fields) {
        let isChanged = false;
        await db.appts.where({ id }).modify(appt => {
            for (let fieldName in fields) {
                const value = fields[fieldName];
                if (appt[fieldName] != value) {
                    appt[fieldName] = value;
                    isChanged = true;
                }
            }
        });
        if (isChanged) {
            API.enqueueCall('appt/update', { id, fields });
        }
    }

    static getServiceType(id) {
        return API.serviceTypesByID[id];
    }

    static getApptPaymentStats(appt) {
        let balance = Number(appt.servicePrice || 0) + Number(appt.productPrice || 0);
        let amountPaid = 0, amountToPay = 0;
        appt.payments.forEach(ap => {
            if (ap.isPaid) {
                amountPaid += ap.amount;
                balance -= ap.amount;
            } else {
                amountToPay += ap.amount;
            }
        });
        return { amountPaid, balance, amountToPay };
    }

    static async clearServicePrices(appt) {
        for (let i = 0; i < appt.services.length; i++) {
            const service = appt.services[i];
            service.price = 0;
            await this.saveApptService(appt.id, service.chimneyID, service.serviceTypeID, {
                price: 0
            });
        }
        // Update appt service price
        appt.servicePrice = 0;
        await this.saveAppt(appt.id, { servicePrice: 0 });
    }

    //------------------------------------------------------------------------------------------------------------------
    // Service type
    //------------------------------------------------------------------------------------------------------------------

    static listServiceTypes() {
        return Object.values(API.serviceTypesByID);
    }

    //------------------------------------------------------------------------------------------------------------------
    // Customer
    //------------------------------------------------------------------------------------------------------------------

    static async saveCustomer(id, fields) {
        let isChanged = false;
        await db.customers.where({ id }).modify(customer => {
            for (let fieldName in fields) {
                const value = fields[fieldName];
                if (customer[fieldName] != value) {
                    customer[fieldName] = value;
                    isChanged = true;
                }
            }
        });
        //fields API.serialiseValue(value)
        if (isChanged) {
            API.enqueueCall('customer/update', { id, fields });
        }
    }

    //------------------------------------------------------------------------------------------------------------------
    // Property
    //------------------------------------------------------------------------------------------------------------------

    static async saveProperty(id, fields) {
        let isChanged = false;
        
        await db.properties.where({ id }).modify(property => {
            for (let fieldName in fields) {
                const value = fields[fieldName];
                
                switch (fieldName) {
                    case 'line1':
                    case 'line2':
                    case 'town':
                    case 'postcode':
                        if (property.address[fieldName] != value) {
                            property.address[fieldName] = value;
                            isChanged = true;
                        }
                        break;
                    default:
                        if (property[fieldName] != value) {
                            property[fieldName] = value;
                            isChanged = true;
                        }
                        break;
                }
            }
        });
        //fieldName, value: API.serialiseValue(value) < - TODO ?
        if (isChanged) {
            await API.enqueueCall('property/update', { id, fields });
        }
    }

    //------------------------------------------------------------------------------------------------------------------
    // Customer Source
    //------------------------------------------------------------------------------------------------------------------

    static async listCustomerSources(accountID) {
        return db.customerSources.where({ accountID }).toArray();
    }

    //------------------------------------------------------------------------------------------------------------------
    // Price Scheme
    //------------------------------------------------------------------------------------------------------------------

    static async listPriceSchemes(accountID) {
        return db.priceSchemes.where({ accountID }).toArray();
    }

    //------------------------------------------------------------------------------------------------------------------
    // Product
    //------------------------------------------------------------------------------------------------------------------

    static async listProducts(accountID, category) {
        let products = await db.products.where({ accountID, isSold: 'true' }).toArray();
        if (category) {
            products = products.filter(p => p.category == category);
        }
        products.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
        return products;
    }

    static async saveProduct(apptID, id, fields) {
        let isChanged = false;
        await db.appts.where({ id: apptID }).modify(appt => {
            let product = appt.products.find(p => p.id == id);
            if (!product) {
                product = { id };
                appt.products.push(product);
            }
            for (let fieldName in fields) {
                const value = fields[fieldName];
                if (product[fieldName] != value) {
                    product[fieldName] = value;
                    isChanged = true;
                }
            }
        });
        if (isChanged) {
            await API.enqueueCall('appt/update-product', { apptID, id, fields });
        }
    }

    //------------------------------------------------------------------------------------------------------------------
    // Chimney / Chimney Type
    //------------------------------------------------------------------------------------------------------------------

    static async listChimneyTypes(accountID) {
        return db.chimneyTypes
            .where('accountID')
            .equals(accountID)
            .or('accountID')
            .equals('any')
            .toArray();
    }

    static async saveChimney(propertyID, chimneyID, fields) {
        let isChanged = false;
        await db.properties.where({ id: propertyID }).modify(property => {
            let index = property.chimneys.findIndex(c => c.id == chimneyID);
            if (index == -1) {
                property.chimneys.push({
                    id: chimneyID,
                    propertyID
                });
                index = property.chimneys.length - 1;
            }
            const chimney = property.chimneys[index];
            for (let fieldName in fields) {
                const value = fields[fieldName];
                if (chimney[fieldName] != value) {
                    chimney[fieldName] = value;
                    isChanged = true;
                }
            }
        });
        if (isChanged) {
            await API.enqueueCall('property/update-chimney', { propertyID, chimneyID, fields });
        }
    }

    static async addChimneyType(name, accountID) {
        const chimneyType = {
            id: TextHelpers.getRandomGUID(),
            name,
            accountID
        };
        await db.chimneyTypes.add(chimneyType);
        API.chimneyTypesByID[chimneyType.id] = chimneyType; 
        await API.enqueueCall('chimney-type/update', { chimneyTypeID: chimneyType.id, accountID: accountID, fields: chimneyType });
        return chimneyType.id;
    }

    //------------------------------------------------------------------------------------------------------------------
    // Payment / Payment Method
    //------------------------------------------------------------------------------------------------------------------

    static listPaymentMethods(accountID) {
        return API.paymentMethods.filter(pm => pm.accountIDs.indexOf(accountID) != -1);
    }

    static async savePayment(apptID, id, fields) {
        let isChanged = false;
        await db.appts.where({ id: apptID }).modify(appt => {
            let apptPayment = appt.payments.find(ap => ap.id == id);
            if (!apptPayment) {
                apptPayment = { id };
                appt.payments.push(apptPayment);
            }
            for (let fieldName in fields) {
                const value = fields[fieldName];
                if (apptPayment[fieldName] != value) {
                    apptPayment[fieldName] = value;
                    isChanged = true;
                }
            }
        });
        if (isChanged) {
            await API.enqueueCall('appt/update-payment', { apptID, id, fields });
        }
    }
}

window.API = API;
