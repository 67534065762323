// Libs
import React from 'react';
import moment from 'moment';

// Services & Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';
import FormHelper from 'helpers/FormHelper';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import ErrorBoundary from 'components/ErrorBoundary';
import ButtonOptions from 'components/common/ButtonOptions';

//-------------------------------------------------------------------------------------------------------------------

class ApptDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = { };

        this.form = new FormHelper({
            fields: {
                'customer.title': {
                    label: 'Title',
                    type: 'text'
                },
                'property.directions': {
                    type: 'expanding-text',
                    rows: 2
                },
                'property.whatThreeWords': {
                    label: 'What.Three.Words',
                    type: 'text',
                    getLabelExtras: () => !!this.props.appt.property.whatThreeWords &&
                        <a className="btn btn-primary btn-sm ms-auto" href={`https://what3words.com/${this.props.appt.property.whatThreeWords}`} target="_blank">
                            View
                        </a>
                },
                'property.isOccupied': {
                    boxLabel: 'Property is occupied',
                    type: 'checkbox'
                },
                'property.tenantName': {
                    label: 'Name'
                },
                'property.tenantEmail': {
                    label: 'Email',
                    type: 'email'
                },
                'property.tenantLandline': {
                    label: 'Landline',
                    type: 'tel'
                },
                'property.tenantMobile': {
                    label: 'Mobile',
                    type: 'tel'
                },
                'property.sendEmailsToTenant': {
                    label: 'Send emails to tenant',
                    type: 'checkbox'
                },
                'customer.firstName': {
                    label: 'First Name',
                    type: 'text'
                },
                'customer.lastName': {
                    label: 'Last Name',
                    type: 'text'
                },
                'customer.companyName': {
                    label: 'Company Name',
                    type: 'text'
                },
                'customer.landlineTel': {
                    label: 'Phone Number',
                    type: 'tel'
                },
                'customer.mobileTel': {
                    label: 'Mobile Number',
                    type: 'tel'
                },
                'customer.emailAddress': {
                    label: 'Email Address',
                    type: 'email'
                },
                'customer.type': {
                    label: 'Type',
                    type: 'single-select',
                    getOptions: () => ReferenceHelpers.CustomerTypes
                },
                'customer.customerSourceID': {
                    label: 'Source',
                    type: 'single-select',
                    blankText: '(Select)',
                    getOptions: () => this.props.customerSources.map(cs => ({
                        value: cs.id,
                        text: cs.name
                    }))
                },
                //doNotSweepReason: {

                //},
                'customer.notes': {
                    label: 'Customer Notes',
                    type: 'expanding-text',
                    rows: 2
                },

                'property.access': {
                    label: 'Access',
                    type: 'expanding-text',
                    rows: 4
                },
                'property.notes': {
                    label: 'Notes',
                    type: 'expanding-text',
                    rows: 4
                },
                'property.priceSchemeID': {
                    label: 'Price Scheme',
                    type: 'single-select',
                    blankText: '(Select)',
                    getOptions: () => this.props.priceSchemes.map(ps => ({
                        value: ps.id,
                        text: ps.name
                    }))
                },

                'address.line1': {
                    label: 'Address 1',
                    type: 'text'
                },
                'address.line2': {
                    label: 'Address 2',
                    type: 'text'
                },
                'address.town': {
                    label: 'Town',
                    type: 'text'
                },
                'address.postcode': {
                    label: 'Postcode',
                    type: 'text'
                },
                'riskAssessment': {
                    label: 'Risk Assessment Details',
                    type: 'expanding-text'
                }
            },
            getValue: (fieldName) => this.props.getFieldValue(fieldName),
            setValue: (fieldName, value) => {
                const save = [
                    'property.priceSchemeID',
                    'property.isOccupied',
                    'property.sendEmailsToTenant'
                ].indexOf(fieldName) != -1;
                this.props.updateFields({ [fieldName]: value }, save);
                if (fieldName == 'property.priceSchemeID') {
                    API.clearServicePrices(this.props.appt);
                }
            },
            onBlur: (fieldName, value) => {
                this.props.updateFields({ [fieldName]: value }, true)
            }
        });
    }

    async componentDidMount() {
        const lastAppt = this.props.appt.property.lastAppt;
        if (lastAppt) {
            await API._loadRelatedApptInfo(lastAppt);
            this.setState({ lastAppt });
        }
    }

    async confirmCancel() {
        const confirm = window.confirm('Are you sure you want to cancel this appointment?');
        if (confirm) {
            this.props.setIsLoading(true);
            await this.props.updateFields({ status: 'CustomerNotIn' }, true);
            this.props.history.replace(`/day-sheet`);
        }
    }

    render() {
        const { appt } = this.props;

        return (
            <div className="appt-details">

                {appt.property &&
                    <div className="property-address">
                        <button type="button" className="btn btn-danger" onClick={() => this.confirmCancel()}>
                            <span className="fa fa-times" />{' '}
                            Cancel (customer not in)
                        </button>

                        Property: {TextHelpers.formatAddress(appt.property.address)}
                    </div>
                }

                <h2>Directions</h2>
                {this.form.render('property.directions')}

                <div className="row">

                    <div className="col-md-6">

                        {this.renderCustomerDetails()}
                        
                    </div>

                    <div className="col-md-6">

                        {this.renderPropertyDetails()}

                    </div>

                </div>

                <div className="row">

                    <div className="col-md-6">

                        {this.renderLastAppt()}

                    </div>

                    <div className="col-md-6">

                        {this.renderThisAppt()}

                    </div>

                </div>
                
            </div>
        );
    }

    renderCustomerDetails() {
        const { appt } = this.props;

        return (<>

            <h2>Customer details</h2>

            <div className="row">
                <div className="col-md-6">
                    {this.form.render('customer.title')}
                </div>
                <div className="col-md-6">
                    {this.form.render('customer.type')}
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    {this.form.render('customer.firstName')}
                </div>
                <div className="col-md-6">
                    {this.form.render('customer.lastName')}
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    {this.form.render('customer.companyName')}
                </div>
                <div className="col-md-6">
                    {this.form.render('customer.emailAddress')}
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    {this.form.render('customer.landlineTel')}
                </div>
                <div className="col-md-6">
                    {this.form.render('customer.mobileTel')}
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    {this.form.render('customer.notes')}
                </div>
                <div className="col-md-6">
                    {this.form.render('customer.customerSourceID')}
                </div>
            </div>

            {!ReferenceHelpers.isIndividual(appt.customer.type) && <>
                {this.form.render('property.isOccupied')}
                {appt.property.isOccupied && this.renderTenantDetails()}
            </>}
            
        </>);
    }

    renderTenantDetails() {
        return (<>
            <h2>Tenant details</h2>

            {this.form.render('property.tenantName')}

            <div className="row">
                <div className="col-md-6">
                    {this.form.render('property.tenantEmail')}
                </div>
                <div className="col-md-6">
                    {this.form.render('property.sendEmailsToTenant')}
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    {this.form.render('property.tenantLandline')}
                </div>
                <div className="col-md-6">
                    {this.form.render('property.tenantMobile')}
                </div>
            </div>

        </>);
    }

    renderPropertyDetails() {
        const { appt } = this.props;

        return (<>

            <h2>Property details</h2>

            <div className="row">
                <div className="col-md-6">
                    {this.form.render('address.line1')}
                    {this.form.render('address.line2')}
                </div>
                <div className="col-md-6">
                    {this.form.render('property.access')}
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    {this.form.render('address.town')}
                </div>
                <div className="col-md-6">
                    {this.form.render('address.postcode')}
                </div>
            </div>

            {this.form.render('property.whatThreeWords')}

            <div className="row">
                <div className="col-md-6">
                    {this.form.render('property.notes')}
                </div>
                <div className="col-md-6">
                    {this.form.render('property.priceSchemeID')}
                </div>
            </div>

            <div className="form-group">
                <label className="form-label">Standard Risk Assessment Applies</label>
                <ButtonOptions
                    options={[
                        { value: true, text: 'Yes' },
                        { value: false, text: 'No (provide details)' }
                    ]}
                    value={appt.isStandardRiskAssessment}
                    onSelect={value => this.props.updateFields({ isStandardRiskAssessment: value }, true)}
                />
            </div>
            {!appt.isStandardRiskAssessment && this.form.render('riskAssessment')}

            {/*
            <div className="form-group">
                <label className="form-label">Rebook months</label>
                <ButtonOptions
                    options={ReferenceHelpers.Months}
                    value={appt.property.rebookMonths}
                    onSelect={value => {
                        const rebookMonths = [...appt.property.rebookMonths];
                        const index = rebookMonths.indexOf(value);
                        if (index == -1) {
                            rebookMonths.push(value);
                        } else {
                            rebookMonths.splice(index, 1);
                        }
                        this.props.updateFields({ 'property.rebookMonths': rebookMonths })
                    }}
                />
            </div>
            */}

        </>);
    }

    renderLastAppt() {
        const { lastAppt } = this.state;
        if (!lastAppt) return null;
        
        return (<>

            <ErrorBoundary>
                <h2>Previous appointment</h2>
                <div className="row">

                    <div className="col-md-6">

                        <strong>Sweep</strong>: {lastAppt.sweepName}<br />
                        <strong>Date</strong>: {moment(lastAppt.date).format('DD/MM/YYYY')}<br />
                        <strong>Time</strong>: {lastAppt.time}<br />

                    </div>

                    <div className="col-md-6">

                        <strong>Duration</strong>: {lastAppt.duration}<br />
                        <strong>Work completed</strong>: {TextHelpers.getApptSummary(lastAppt)}<br />
                        <strong>Price:</strong> {TextHelpers.formatCurrency(lastAppt.servicePrice)}
                        
                    </div>
                    
                </div>

                {lastAppt.services.map((ast, index) =>
                    <React.Fragment key={index}>
                        {this.renderLastApptService(ast)}
                    </React.Fragment>
                )}

            </ErrorBoundary>

        </>);
    }

    renderLastApptService(service) {
        const chimney = service.chimney;
        const chimneyType = (chimney && chimney.chimneyTypeID ? API.chimneyTypesByID[chimney.chimneyTypeID] : null);
        const serviceType = API.serviceTypesByID[service.serviceTypeID] || {};

        try {
            return (<>
                {chimney &&
                    <h3>
                        {chimneyType && <>{' '}{chimneyType.name},{' '}</>}
                        {chimney.floor} floor {chimney.location}
                    </h3>
                }

                <strong>Job: </strong>{serviceType.name}<br />
                <strong>Result: </strong>{service.status}<br />
                {service.failReason && <>
                    <strong>Reason: </strong>{service.failReason}<br />
                </>}
                {service.failReasonNotes && <>
                    <strong>Failure notes: </strong>{service.failReasonNotes}<br />
                </>}
                {service.condemnNotes && <>
                    <strong>Condemn notes: </strong>{service.condemnNotes}<br />
                </>}
                {service.notes && <>
                    <strong>Internal notes: </strong>{service.notes}<br />
                </>}
                {chimney && <>
                    <strong>CO Alarm: </strong>{service.coAlarmStatus}<br />
                    {service.coAlarmStatus != 'NotFitted' && typeof (service.isCOAlarmLocationCorrect) != undefined && service.isCOAlarmLocationCorrect !== null && <>
                        <strong>CO Alarm in correct location: </strong>{service.isCOAlarmLocationCorrect ? 'Yes' : 'No'}<br />
                    </>}
                    {typeof (service.isFlueTerminalFitted) != undefined && service.isFlueTerminalFitted !== null && <>
                        <strong>Appropriate flue terminal fitted: </strong>{service.isFlueTerminalFitted ? 'Yes' : 'No'}<br />
                    </>}
                    {typeof (service.isDrawTestPassed) != undefined && service.isDrawTestPassed !== null && <>
                        <strong>Draw (smoke) test passed: </strong>{service.isDrawTestPassed ? 'Yes' : 'No'}<br />
                    </>}
                    {service.drawTestFailReason && <>
                        <strong>Draw test fail reason: </strong>{service.drawTestFailReason}<br />
                    </>}
                    {service.nextSweepInMonths && <>
                        <strong>Next sweep recommended in: </strong>{service.service.nextSweepInMonths}<br />
                    </>}
                    {service.isFlueFreeRecommended && <>
                        <strong>Recommendations: </strong>{service.isFlueFreeRecommended ? 'Flue Free' : ''}<br />
                    </>}
                    {service.otherRecommendations && <>
                        <strong>Other Recommendations: </strong>{service.otherRecommendations}<br />
                    </>}
                </>}
            </>);
        } catch (e) {
            return null;
        }
    }

    renderThisAppt() {
        const { appt } = this.props;

        return (<>

            <h2>This appointment</h2>

            <div className="row">

                <div className="col-md-6">

                    <div className="form-group">
                        <label className="form-label">Work required</label>
                        <p className="form-control-plaintext">
                            {TextHelpers.getApptSummary(appt)}
                        </p>
                    </div>
                    
                </div>

                <div className="col-md-3">

                    <div className="form-group">
                        <label className="form-label">Duration</label>
                        <p className="form-control-plaintext">
                            {appt.duration}
                        </p>
                    </div>

                </div>

                <div className="col-md-3">

                    <div className="form-group">
                        <label className="form-label">Price</label>
                        <p className="form-control-plaintext">
                            {appt.servicePrice ? TextHelpers.formatCurrency(appt.servicePrice) : '(Unspecified)'}
                        </p>
                    </div>

                </div>

            </div>

        </>);
    }
}

export default ApptDetails;