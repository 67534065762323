import React from 'react';
import { NumericFormat } from 'react-number-format';

class CurrencyInput extends React.Component {

    constructor(props) {
        super(props);
        this.onBlur = this.onBlur.bind(this);
    }

    onBlur(e) {
        if (this.props.onBlur && this.isChanged) {
            this.props.onBlur(this.value, e);
            delete this.isChanged;
        }
    }

    render() {
        return (
            <NumericFormat
                prefix="£"
                thousandSeparator=","
                decimalSeparator={'.'}
                {...this.props}
                onValueChange={(values, e) => {
                    this.value = Number(values.value) || 0;
                    this.isChanged = true;
                    this.props.onValueChange(values, e);
                }}
                onBlur={this.onBlur}
            />
        );
    }

}

export default CurrencyInput;