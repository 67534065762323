// Libs
import React from 'react';
import ExpandingTextArea from 'react-expanding-textarea';

// Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import ButtonOptions from 'components/common/ButtonOptions';
import EditChimneyModal from 'components/EditChimneyModal';

//-------------------------------------------------------------------------------------------------------------------

class ApptJobs extends React.Component {

    constructor(props) {
        super(props);
        this.editChimneyModalRef = React.createRef();
        this.state = {
            isEditMode: false
        };
    }

    confirmRemoveService(chimneyID, serviceTypeID) {
        const confirm = window.confirm('Are you sure you want to remove this job from the appointment?');
        if (confirm) {
            this.props.removeService(chimneyID, serviceTypeID);
        }
    }

    async editChimney(chimney) {
        const { appt } = this.props;
        const isChanged = await this.editChimneyModalRef.current.open({
            chimney,
            propertyID: appt.propertyID,
            accountID: appt.accountID
        });
        if (isChanged) {
            this.props.load();
        }
    }

    async addChimney() {
        const { appt } = this.props;
        const chimney = {
            id: TextHelpers.getRandomGUID(),
            floor: 'Ground',
            location: 'Living Room',
            propertyID: appt.propertyID
        };
        await this.editChimney(chimney);
        this.props.load();
    }
    
    render() {
        const { appt } = this.props;
        const { isEditMode } = this.state;

        return (<>
            <div className="appt-jobs">

                <label className="checkbox-label add-jobs-checkbox">
                    <input
                        type="checkbox"
                        checked={isEditMode || false}
                        onChange={e => this.setState({ isEditMode: e.target.checked })}
                    />{' '}
                    Edit mode
                </label>

                {appt.property.chimneys.map((c, index) =>
                    this.renderChimney(c, index)
                )}

                {this.renderOtherServices()}

                {isEditMode &&
                    <button type="button" className="btn btn-secondary" onClick={() => this.addChimney()}>
                        Add a new chimney
                    </button>
                }

            </div>

            <EditChimneyModal ref={this.editChimneyModalRef} />
        </>);
    }

    renderChimney(chimney, index) {
        const { appt } = this.props;
        const { isEditMode } = this.state;

        const hasAnyServices = appt.services.filter(ast => !ast.isDeleted).find(ast => ast.chimneyID == chimney.id);
        if (!hasAnyServices && !isEditMode) {
            return null;
        }
        const chimneyType = (chimney.chimneyTypeID ? API.chimneyTypesByID[chimney.chimneyTypeID] : null);
        const sweepService = appt.services.find(ast => ast.chimneyID == chimney.id && API.serviceTypesByID[ast.serviceTypeID].code == 'sweep');
        const nestService = appt.services.find(ast => ast.chimneyID == chimney.id && API.serviceTypesByID[ast.serviceTypeID].code == 'nest');
        const cowlService = appt.services.find(ast => ast.chimneyID == chimney.id && API.serviceTypesByID[ast.serviceTypeID].code == 'cowl');
        const cctvService = appt.services.find(ast => ast.chimneyID == chimney.id && API.serviceTypesByID[ast.serviceTypeID].code == 'cctv');
        const sweepOrNestService = nestService || sweepService;
        
        return (
            <div key={chimney.id} className="chimney">

                <h2>
                    <span>
                        {chimneyType && <>{' '}{chimneyType.name},{' '}</>}
                        {chimney.floor} floor {chimney.location}

                        {isEditMode &&
                            <button
                                type="button"
                                className="btn btn-secondary edit-chimney-btn"
                                onClick={() => this.editChimney(chimney)}
                            >
                                <span className="fa-solid fa-pencil" />
                            </button>
                        }

                    </span>
                </h2>

                <div className="chimney-services">

                    {(sweepOrNestService || isEditMode) &&
                        <div className={`chimney-service ${sweepOrNestService ? 'active' : ''}`}>
                            <div className="service-type">
                                <span>
                                    Sweep
                                    {nestService && <><br />+ Nest</>}
                                </span>
                            </div>
                            <div className="service-results">

                                {sweepOrNestService ?
                                    this.renderSweepResults(sweepOrNestService, !!nestService) :
                                    <div className="empty-text">
                                        <button className="btn btn-secondary add-service-btn" onClick={() => this.props.addService(chimney.id, 'sweep')}>
                                            <span className="fa fa-plus" />{' '}
                                            Add
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>
                    }
                    {!sweepOrNestService && isEditMode &&
                        <div className="chimney-service">
                            <div className="service-type">
                                <span>
                                    Nest
                                </span>
                            </div>
                            <div className="service-results">

                                <div className="empty-text">
                                    <button className="btn btn-secondary add-service-btn" onClick={() => this.props.addService(chimney.id, 'nest')}>
                                        <span className="fa fa-plus" />{' '}
                                        Add
                                    </button>
                                </div>

                            </div>
                        </div>
                    }
                    {(cowlService || isEditMode) &&
                        <div className={`chimney-service ${!!cowlService ? 'active' : ''}`}>
                            <div className="service-type">
                                <span>Fit Cowl</span>
                            </div>
                            <div className="service-results">

                                {cowlService ?
                                    this.renderCowlResults(cowlService) :
                                    <div className="empty-text">
                                        <button className="btn btn-secondary add-service-btn" onClick={() => this.props.addService(chimney.id, 'cowl')}>
                                            <span className="fa fa-plus" />{' '}
                                            Add
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>
                    }
                    {(cctvService || isEditMode) &&
                        <div className={`chimney-service ${!!cctvService ? 'active' : ''}`}>
                            <div className="service-type">
                                <span>CCTV Survey</span>
                            </div>
                            <div className="service-results">

                                {cctvService ?
                                    this.renderCCTVResults(cctvService) :
                                    <div className="empty-text">
                                        <button className="btn btn-secondary add-service-btn" onClick={() => this.props.addService(chimney.id, 'cctv')}>
                                            <span className="fa fa-plus" />{' '}
                                            Add
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>
                    }
                </div>

            </div>
        );
    }

    renderSweepResults(service, isNest) {
        const { isEditMode } = this.state;

        return (<>

            {isEditMode &&
                <button type="button" className="btn btn-danger delete-service-btn" onClick={() => this.confirmRemoveService(service.chimneyID, service.serviceTypeID)}>
                    <span className="fa-solid fa-remove" />
                </button>
            }

            {isEditMode &&
                <div className="form-group">
                    <label className="form-label">Job Type</label>
                    <ButtonOptions
                        options={[
                            { value: 'Sweep', text: 'Sweep only' },
                            { value: 'Nest', text: 'Sweep + nest removal' }
                        ]}
                        value={isNest ? 'Nest' : 'Sweep'}
                        onSelect={value => this.props.toggleSweepNest(service.chimneyID, service.serviceTypeID, (value == 'Nest'))}
                    />
                </div>
            }

            {service.chimneyID &&
                <div className="form-group">
                    <label className="form-label">Chimney Notes</label>
                    <ExpandingTextArea
                        className="form-control"
                        key={service.chimney.notes || ''}
                        defaultValue={service.chimney.notes || ''}
                        onBlur={e => this.props.updateChimneyFields(service.chimneyID, { notes: e.target.value })}
                        rows={2}
                    />
                </div>
            }

            {this.renderStatusFields(service, true)}

            {/* {this.renderNotesField(service)}*/}

            <div className="form-group">
                <label className="form-label">Recommendations</label>
                <ButtonOptions
                    options={[
                        { value: 'isFlueFreeRecommended', text: 'We recommend regular use of HotSpot Flue Free to help prevent the tar/creosote buildup' }
                    ]}
                    value={{
                        isFlueFreeRecommended: service.isFlueFreeRecommended
                    }}
                    onSelect={value => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { [value]: !service[value] }, true)}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Other Recommendations</label>
                <ExpandingTextArea
                    className="form-control"
                    key={service.otherRecommendations || ''}
                    defaultValue={service.otherRecommendations || ''}
                    onBlur={e => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { otherRecommendations: e.target.value }, true)}
                    rows={2}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Carbon monoxide alarm</label>
                <ButtonOptions 
                    options={[
                        { value: 'Working', text: 'Fitted & working' },
                        { value: 'NotTested', text: 'Fitted, not tested' },
                        { value: 'NotWorking', text: 'Fitted, not working' },
                        { value: 'NotFitted', text: 'Not fitted' }
                    ]}
                    value={service.coAlarmStatus}
                    onSelect={value => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { coAlarmStatus: value }, true)}
                />
            </div>
            {service.coAlarmStatus != 'NotFitted' &&
                <div className="form-group">
                    <label className="form-label">Carbon monoxide alarm in correct location</label>
                    <ButtonOptions
                        options={[
                            { value: true, text: 'Yes' },
                            { value: false, text: 'No' }
                        ]}
                        value={service.isCOAlarmLocationCorrect}
                        onSelect={value => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { isCOAlarmLocationCorrect: value }, true)}
                    />
                </div>
            }
            <div className="form-group">
                <label className="form-label">Appropriate flue terminal fitted</label>
                <ButtonOptions
                    options={[
                        { value: true, text: 'Yes' },
                        { value: false, text: 'No' }
                    ]}
                    value={service.isFlueTerminalFitted}
                    onSelect={value => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { isFlueTerminalFitted: value }, true)}
                />
            </div>
            <div className="form-group">
                <label className="form-label">Draw (smoke) test</label>
                <ButtonOptions
                    options={[
                        { value: null, text: 'Not performed' },
                        { value: true, text: 'Pass' },
                        { value: false, text: 'Fail' }
                    ]}
                    value={service.isDrawTestPassed}
                    onSelect={value => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { isDrawTestPassed: value }, true)}
                />
            </div>
            {service.isDrawTestPassed === false &&
                <div className="form-group">
                    <label className="form-label">Draw test fail reason</label>
                    <ExpandingTextArea
                        className="form-control"
                        key={service.drawTestFailReason || ''}
                        defaultValue={service.drawTestFailReason || ''}
                        onBlur={e => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { drawTestFailReason: e.target.value }, true)}
                        rows={2}
                    />
                </div>
            }
            <div className="form-group">
                <label className="form-label">Next sweep recommended in...</label>
                <ButtonOptions
                    options={[
                        { value: null, text: 'N/A' },
                        { value: 1, text: '1 Month' },
                        { value: 3, text: '3 Months' },
                        { value: 6, text: '6 Months' },
                        { value: 12, text: '12 Months' },
                        { value: 24, text: '24 Months' }//,
                        //{ value: 36, text: '36 Months' },
                        //{ value: 48, text: '48 Months' }
                    ]}
                    value={service.nextSweepInMonths}
                    onSelect={value => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { nextSweepInMonths: value }, true)}
                />
            </div>
        </>);
    }
    
    renderCowlResults(service) {
        const { isEditMode } = this.state;
        const cowlProducts = this.props.products.filter(p => p.category == 'Cowl');

        return (<>

            {isEditMode &&
                <button type="button" className="btn btn-danger delete-service-btn" onClick={() => this.confirmRemoveService(service.chimneyID, service.serviceTypeID)}>
                    <span className="fa-solid fa-remove" />
                </button>
            }

            {this.renderStatusFields(service, false)}

            <div className="form-group">
                <label className="form-label">Cowl type</label>
                <select
                    className="form-control"
                    value={service.cowlProductID || ''}
                    onChange={e => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { cowlProductID: e.target.value }, true)}
                >
                    <option value="">(Other)</option>
                    {cowlProducts.map(p =>
                        <option key={p.id} value={p.id}>
                            {p.name}
                        </option>
                    )}
                </select>
            </div>

            {!service.cowlTypeID &&
                <div className="form-group">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Cowl type..."
                        key={service.otherCowlName || ''}
                        defaultValue={service.otherCowlName || ''}
                        onBlur={e => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { otherCowlName: e.target.value }, true)}
                    />
                </div>
            }

            {service.status == 'OK' &&
                <div className="form-group">
                    <label className="form-label">Quantity supplied / fitted</label>
                    <input
                        className="form-control"
                        type="number"
                        key={service.cowlQuantity || ''}
                        defaultValue={service.cowlQuantity || ''}
                        onBlur={e => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { cowlQuantity: e.target.value }, true)}
                    />
                </div>
            }

            {this.renderNotesField(service)}

        </>);
    }

    renderCCTVResults(service) {
        const { isEditMode } = this.state;

        return (<>

            {isEditMode &&
                <button type="button" className="btn btn-danger delete-service-btn" onClick={() => this.confirmRemoveService(service.chimneyID, service.serviceTypeID)}>
                    <span className="fa-solid fa-remove" />
                </button>
            }

            {this.renderStatusFields(service, false)}

            {this.renderNotesField(service)}

        </>);
    }

    renderStatusFields(service, allowCondemn) {
        const statusOptions = [
            { value: 'OK', text: 'Completed ok' },
            { value: 'Fail', text: 'Could not complete' },
        ];
        if (allowCondemn) {
            statusOptions.push({ value: 'Condemn', text: 'Not safe for use (condemn)' });
        }

        return (<>

            <div className="form-group">
                <label className="form-label">Result</label>
                <ButtonOptions
                    options={statusOptions}
                    value={service.status}
                    onSelect={value => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { status: value }, true)}
                />
            </div>

            {service.status == 'Fail' && <>
                <div className="form-group">
                    <label className="form-label">Main reason for failure</label>
                    <ButtonOptions
                        options={[
                            { value: 'NoAccess', text: 'No access' },
                            { value: 'NestInFlue', text: 'Nest in flue' },
                            { value: 'MasonryInFlue', text: 'Masonry in flue' },
                            { value: 'ExternalAccessOnly', text: 'External access only' },
                            { value: 'RoofAccessRequired', text: 'Roof access required' },
                            { value: 'Other', text: 'Other (please specify)' }
                        ]}
                        value={service.failReason}
                        onSelect={value => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { failReason: value }, true)}
                    />
                </div>
                <div className="form-group">
                    <label className="form-label">Failure Notes</label>
                    <ExpandingTextArea
                        className="form-control"
                        key={service.failReasonNotes || ''}
                        defaultValue={service.failReasonNotes || ''}
                        onBlur={e => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { failReasonNotes: e.target.value }, true)}
                        rows={2}
                    />
                </div>
            </>}

            {allowCondemn && service.status == 'Condemn' &&
                <div className="form-group">
                    <label className="form-label">Details of condemnation</label>
                    <ExpandingTextArea
                        className="form-control"
                        key={service.condemnNotes || ''}
                        defaultValue={service.condemnNotes || ''}
                        onBlur={e => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { condemnNotes: e.target.value }, true)}
                        rows={2}
                    />
                </div>
            }

        </>);
    }

    renderNotesField(service) {
        return (
            <div className="form-group">
                <label className="form-label">Notes (internal)</label>
                <ExpandingTextArea
                    className="form-control"
                    key={service.notes || ''}
                    defaultValue={service.notes || ''}
                    onBlur={e => this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { notes: e.target.value }, true)}
                    rows={2}
                />
            </div>
        );
    }

    renderOtherServices() {
        const { appt } = this.props;
        const { isEditMode } = this.state;
        const serviceTypes = API.listServiceTypes().filter(st => !st.requiresChimney);
        const apptServices = [];
        const apptServicesLookup = {};
        appt.services.forEach(ast => {
            if (!ast.serviceType.requiresChimney) {
                apptServicesLookup[ast.serviceTypeID] = true;
                apptServices.push(ast);
            }
        });
        if (!isEditMode && apptServices.length == 0) {
            return null;
        }

        return (<>

            {isEditMode && <>
                <h2>Other jobs</h2>
                <ButtonOptions
                    className="vertical-stack mb-3"
                    options={serviceTypes.map(st => ({
                        value: st.id,
                        text: st.name
                    }))}
                    value={apptServicesLookup}
                    //value={service.coAlarmStatus}
                    onSelect={serviceTypeID => {
                        if (apptServicesLookup[serviceTypeID]) {
                            this.props.removeNonChimneyService(serviceTypeID);
                        } else {
                            this.props.addNonChimneyService(serviceTypeID);
                        }
                    }}
                />
            </>}

            {apptServices.map(ast =>
                <React.Fragment key={ast.serviceTypeID}>
                    <h2>{ast.serviceType.name}</h2>

                    {this.renderStatusFields(ast, false)}

                    {this.renderNotesField(ast)}
                    
                </React.Fragment>
            )}

        </>);
    }
}

export default ApptJobs;