// Libs
import React from 'react';
import moment from 'moment';
import queryString from 'query-string';

// Services & helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';
import db from 'db';

// Components
import Loader from 'components/common/Loader';
import NavPills from 'components/common/NavPills';
import ErrorBoundary from 'components/ErrorBoundary';
import NextApptModal from 'components/NextApptModal';

//-------------------------------------------------------------------------------------------------------------------

class DaySheet extends React.Component {

    constructor(props) {
        super(props);

        this.nextApptModalRef = React.createRef();

        const qs = queryString.parse(props.history.location.search);

        this.state = {
            isLoading: true,
            isDownloading: false,
            showNextAppt: (qs['show-next-appt'] == 'true'),
            appts: [],
            overdueAppts: [],
            todaysAppts: [],
            futureAppts: [],
            completedAppts: [],
            daySheetNotes: []
        }
    }

    async componentDidMount() {
        this.loadTab('');
        //const isQueueProcessed = await API.isQueueProcessed();
        //if (isQueueProcessed) {
        //    this.downloadLatestData();
        //}
    }

    componentDidUpdate(oldProps) {
        const tab = (this.props.tab || '');
        if (tab != (oldProps.tab || '')) {
            this.loadTab(tab);
        }
    }

    loadTab(tab) {
        this.setState({ isLoading: true });
        if (tab == '') {
            this.load('Open');
        } else if (tab == 'completed') {
            this.load('Completed');
        }
    }

    async load(status) {
        const appts = await API.listAppts(status);
        const daySheetNotes = await API.listDaySheetNotes(new Date());

        // Group by overdue, today or future
        const todayInt = parseInt(moment().format('YYYYMMDD'));
        const overdueAppts = appts.filter(a => parseInt(moment(a.date).format('YYYYMMDD')) < todayInt);
        const todaysAppts = appts.filter(a => parseInt(moment(a.date).format('YYYYMMDD')) == todayInt);
        const futureAppts = appts.filter(a => parseInt(moment(a.date).format('YYYYMMDD')) > todayInt);
        const completedAppts = todaysAppts;

        // Show next appt
        const showNextAppt = this.state.showNextAppt;

        // Show on UI
        this.setState({
            appts,
            overdueAppts,
            todaysAppts,
            futureAppts,
            completedAppts,
            daySheetNotes,
            isLoading: false,
            showNextAppt: false
        }, () => {
            if (showNextAppt) {
                this.showNextAppt(true);
            }
        });
    }

    selectAppt(appt) {
        if (appt.customerID) {
            this.props.history.push(`/appt/${appt.id}`);
        } else {
            const confirm = window.confirm(`Complete this ${appt.nonBookingApptTypeName} appointment?`);
            if (confirm) {
                this.completeAppt(appt.id);
            }
        }
    }

    async completeAppt(id) {
        await API.saveAppt(id, { status: 'Completed' });
        this.loadTab(this.props.tab || '');
    }

    async downloadLatestData() {
        const isQueueProcessed = await API.isQueueProcessed();
        if (isQueueProcessed) {
            this.setState({ isDownloading: true });
            try {
                await API.downloadLatestData();
                this.setState({
                    isDownloading: false
                }, () => {
                    this.loadTab(this.props.tab || '');
                });
            } catch (error) {
                this.setState({ isDownloading: false });
                alert('There was an error downloading the latest day sheet. Are you definitely connected to the internet?');
                window.updateSyncStatus('error', error);
            }
        } else {
            alert('Please wait until all data has been uploaded before downloading the latest day sheet');
        }
    }

    getMapURL(appts) {
        const urlParts = [];
        appts.forEach(a => {
            if (a.property && a.property.address.postcode) {
                urlParts.push(a.property.address.postcode);
            }
        });
        if (urlParts.length > 0) {
            return 'https://www.google.co.uk/maps/dir/current+location/' + urlParts.join('/');
        }
        return null;
    }

    getNextAppt() {
        const { todaysAppts, futureAppts } = this.state;
        const appts = todaysAppts.concat(futureAppts);
        for (let i = 0; i < appts.length; i++) {
            const appt = appts[i];
            if (appt.customer) {
                return appt;
            }
        }
        return null;
    }

    showNextAppt(silent) {
        const nextAppt = this.getNextAppt();
        if (nextAppt) {
            this.nextApptModalRef.current.open({ appt: nextAppt });
        } else if (!silent) {
            alert('You have no more customer appointments from today onwards');
        }
    }

    async confirmLogOut() {
        const confirm = window.confirm('Are you sure you want to log out (NOTE: this will clear all data, including pending uploads)?');
        if (confirm) {
            // Clear all cookies
            document.cookie.split(';').forEach((c) => {
                document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
            });
            // Clear Dexie
            await db.delete();
            window.location.reload();
        }
    }

    render() {
        const { appts, daySheetNotes, overdueAppts, todaysAppts, futureAppts, completedAppts } = this.state;
        const tab = this.props.tab || '';

        return (<>

            {this.renderButtonPanel()}

            {daySheetNotes.length > 0 &&
                <section className="day-sheet-notes">
                    <div className="inner">
                        {daySheetNotes.map(dsn =>
                            <React.Fragment key={dsn.accountID}>
                                {daySheetNotes.length > 1 && <h2>{dsn.account.name}</h2>}
                                <div>
                                    <span className="fa-regular fa-clipboard" />{' '}
                                    {dsn.notes}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </section>
            }

            <NavPills className="section-nav">
                <NavPills.Pill
                    icon="fa-solid fa-list"
                    title="List view"
                    isActive={tab == ''}
                    url={`/day-sheet`}
                />
                {/*
                <NavPills.Pill
                    icon="fa-solid fa-calendar-days"
                    title="Calendar view"
                    isActive={tab == 'calendar'}
                    url={`/day-sheet/calendar`}
                />
                */}
                <NavPills.Pill
                    icon="fa-solid fa-check"
                    title="Completed today"
                    isActive={tab == 'completed'}
                    url={`/day-sheet/completed`}
                />
            </NavPills>
            
            <section>
                
                <ErrorBoundary>
                    
                    {tab == '' && this.renderApptLists(overdueAppts, todaysAppts, futureAppts, null)}
                    {tab == 'completed' && this.renderApptLists(null, null, null, completedAppts)}

                    {appts.length == 0 &&
                        <div className="empty-text">
                            No appointments
                        </div>
                    }
                </ErrorBoundary>

            </section>

            <NextApptModal ref={this.nextApptModalRef} />
        </>);
    }

    renderButtonPanel() {
        const { isDownloading, todaysAppts } = this.state;
        const mapURL = this.getMapURL(todaysAppts);
        const nextAppt = this.getNextAppt();

        return (<>

            <section>

                <div className="button-panel">

                    <div className="left">

                        <button type="button" className="btn btn-primary" onClick={() => this.downloadLatestData()} disabled={isDownloading}>
                            {isDownloading ?
                                <Loader isInline={true} /> :
                                <span className="fa fa-arrows-rotate" />
                            }{' '}
                            Download latest day sheet
                        </button>

                        <button type="button" className="btn btn-secondary" disabled={!nextAppt} onClick={() => this.showNextAppt(false)}>
                            <span className="fa fa-search" />{' '}
                            Show next appointment
                        </button>

                    </div>

                    {mapURL &&
                        <div className="right">

                            <a href={mapURL} target="_blank" className="btn btn-secondary">
                                <span className="fa fa-location-dot" />{' '}
                                View today's day sheet on map
                            </a>

                        </div>
                    }

                    <button type="button" className="btn btn-secondary ms-auto" onClick={() => this.confirmLogOut()}>
                        <span className="fa fa-times" />{' '}
                        Log out
                    </button>

                </div>

            </section>

        </>);
    }

    renderApptLists(overdueAppts, todaysAppts, futureAppts, completedAppts) {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            {overdueAppts && overdueAppts.length > 0 && <>
                <h2>Overdue appointments</h2>
                {this.renderApptsTable(overdueAppts, 'overdue')}
            </>}

            {todaysAppts && todaysAppts.length > 0 && <>
                <h2>Today's appointments</h2>
                {this.renderApptsTable(todaysAppts)}
            </>}

            {futureAppts && futureAppts.length > 0 && <>
                <h2>Future appointments (next 7 days)</h2>
                {this.renderApptsTable(futureAppts)}
            </>}

            {completedAppts && completedAppts.length > 0 && <>
                <h2>Completed appointments</h2>
                {this.renderApptsTable(completedAppts)}
            </>}
            
        </>);
    }

    renderApptsTable(appts, className) {
        let apptsByDate = {};
        appts.forEach(a => {
            if (!apptsByDate[a.date]) {
                apptsByDate[a.date] = [];
            }
            apptsByDate[a.date].push(a);
        });
        
        return (
            <table className={`appts-table ${className || ''}`}>
                <thead>
                    <tr>
                        <th className="time-col">Time</th>
                        <th className="customer-col">Customer</th>
                        <th className="phone-col">Phone(s)</th>
                        <th className="job-col">Job(s)</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(apptsByDate).map(date => {
                        const apptsThisDate = apptsByDate[date];
                        const mapURL = this.getMapURL(apptsThisDate);
                        return (
                            <React.Fragment key={date}>
                                <tr className="header">
                                    <td colSpan={5}>
                                        <div>
                                            {moment(date).format('dddd, DD MMMM YYYY')}

                                            {!!mapURL &&
                                                <a href={mapURL} target="_blank" className="btn btn-sm btn-tertiary map-btn" onClick={e => e.stopPropagation()}>
                                                    <span className="fa-solid fa-location-dot" />{' '}
                                                    View all on map
                                                </a>
                                            }
                                        </div>
                                    </td>
                                </tr>
                                {apptsThisDate.map(a => this.renderAppt(a))}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    renderAppt(appt) {
        const style = {};
        if (appt.nonBookingApptTypeName) {
            style.backgroundColor = appt.backColour;
            style.color = appt.textColour;
        }

        // Get row class
        const classNames = ['appt'];
        for (let i = 0; i < appt.services.length; i++) {
            const ast = appt.services[i];
            if (!ast.serviceType) continue;
            const serviceTypeCode = (ast.serviceType.code ? ast.serviceType.code.toUpperCase() : ast.serviceType.name);
            if (serviceTypeCode == 'COWL') {
                classNames.push('has-cowl');
                break;
            }
        }

        return (
            <tr key={appt.id} className={classNames.join(' ')} onClick={() => this.selectAppt(appt)}>
                <td className="time-col" style={style}>

                    {this.renderApptTime(appt)}

                </td>
                <td className="customer-col" style={style}>
                    
                    {appt.property && <>

                        {appt.property.whatThreeWords &&
                            <a href={`https://what3words.com/${appt.property.whatThreeWords}`} target="_blank" className="btn btn-tertiary map-btn ms-2" onClick={e => e.stopPropagation()}>
                                W3W
                            </a>
                        }

                        <a href={`https://www.google.com/maps/dir/my+location/${appt.property.address.postcode}`} target="_blank" className="btn btn-tertiary map-btn" onClick={e => e.stopPropagation()}>
                            <span className="fa-solid fa-location-dot" />{' '}
                            Map
                        </a>

                    </>}

                    <div className="mobile-only">

                        {this.renderApptTime(appt)}

                    </div>

                    {appt.customer &&
                        <div className="customer-name">
                            {appt.customer.companyName &&
                                <div>
                                    {appt.customer.companyName}
                                </div>
                            }
                            {appt.customer.firstName &&
                                <div>
                                    {appt.customer.title} {appt.customer.firstName} {appt.customer.lastName}
                                </div>
                            }
                        </div>
                    }
                    {!!appt.nonBookingApptTypeName && <>
                        <div className="non-booking-appt-type-name">
                            {appt.nonBookingApptTypeName}
                        </div>
                    </>}
                    {appt.property &&
                        <div className="property-address">
                            {TextHelpers.formatAddress(appt.property.address)}
                        </div>
                    }
                    {appt.notes &&
                        <div className="appt-notes">
                            {appt.notes}
                        </div>
                    }
                    {!!appt.bookedOnDate &&
                        <div className="booked-date">
                            Booked on {moment(appt.bookedOnDate).format('DD/MM/YYYY')}
                            {appt.bookedByName && <>
                                {' '}by {appt.bookedByName}
                            </>}
                        </div>
                    }

                </td>
                <td className="phone-col" style={style}>

                    {appt.customer && <>

                        {!!appt.customer.mobileTel &&
                            <a className="btn btn-tertiary" href={`tel:${appt.customer.mobileTel}`}>
                                <span className="fa-solid fa-phone" />{' '}
                                {appt.customer.mobileTel}
                            </a>
                        }

                        {!!appt.customer.landlineTel &&
                            <a className="btn btn-tertiary" href={`tel:${appt.customer.landlineTel}`}>
                                <span className="fa-solid fa-phone" />{' '}
                                {appt.customer.landlineTel}
                            </a>
                        }

                    </>}

                    {appt.property && <>
                        {!!appt.property.tenantMobile &&
                            <a className="btn btn-tertiary" href={`tel:${appt.property.tenantMobile}`}>
                                <span className="fa-solid fa-phone" />{' '}
                                {appt.property.tenantMobile} (Tenant)
                            </a>
                        }
                        {!!appt.property.tenantLandline &&
                            <a className="btn btn-tertiary" href={`tel:${appt.property.tenantLandline}`}>
                                <span className="fa-solid fa-phone" />{' '}
                                {appt.property.tenantLandline} (Tenant)
                            </a>
                        }
                    </>}

                </td>
                <td className="job-col" style={style}>

                    {TextHelpers.getApptSummary(appt)}

                </td>
            </tr>
        );
    }

    renderApptTime(appt, className) {
        return (
            <div className={`appt-time ${className || ''}`}>
                <span className="fa-regular fa-clock" />{' '}
                <div className="time-duration">
                    <div className="time">
                        {appt.time}
                    </div>
                    <div className="duration">
                        {appt.duration} mins
                    </div>
                </div>
            </div>
        );
    }
}

export default DaySheet;